import React from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { getIsInIFrame } from '../selectors/is-in-iframe';
import { getHasReportedAppLoad } from '../store/app-loaded/app-loaded-selectors';
import { initWindowResize } from '../services/communities-window-resize';

export const withCommunitiesWindowResize = (WrappedComponent) => {
  class WithCommunitiesWindowResize extends React.Component {
    state = { stopListening: () => {} };
    onWindowResizeInit = ({ stopListening }) => {
      this.setState({ stopListening });
    };

    componentDidMount() {
      if (!getIsInIFrame(this.props.state)) {
        return;
      }

      Wix.addEventListener(Wix.Events.PAGE_NAVIGATION, (event) => {
        Wix.getCurrentPageId((pageId) => {
          if (pageId === event.toPage) {
            this.state.stopListening();
          }
        });
      });
    }

    componentDidUpdate(prevProps) {
      if (!getIsInIFrame(this.props.state)) {
        return;
      }

      const appLoaded = getHasReportedAppLoad(this.props.state);
      if (appLoaded && appLoaded !== getHasReportedAppLoad(prevProps.state)) {
        initWindowResize(this.onWindowResizeInit);
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  hoistNonReactStatics(WithCommunitiesWindowResize, WrappedComponent);

  return WithCommunitiesWindowResize;
};
